
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'
import Tradeinable from '@/components/Tradein/Tradeinable'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

export default {
  components: {
    Breadcrumbs,
    Tradeinable
  },

  mixins: [
    vueTelInputAllCountries
  ],

  middleware ({ store, redirect, app }) {
    if (store.state.tradein.items.length === 0 ||
      store.state.tradein.items.map(i => i.category).find(c => c === null) !== undefined ||
      store.state.tradein.items.map(i => i.product).find(p => p === null) !== undefined ||
      store.state.tradein.items.find(i => i.options.length === 0 || i.options.find(o => o.value === null)) !== undefined
    ) {
      return redirect(app.localePath({ name: 'tradein' }))
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.tradein.title'),
          to: this.localePath('tradein'),
          active: false
        },
        {
          title: this.$t('pages.tradein.checkout.title'),
          to: '#',
          active: true
        }
      ],
      form: {
        user: {
          name: {
            value: '',
            error: ''
          },
          lastname: {
            value: '',
            error: ''
          },
          patronymic: {
            value: '',
            error: ''
          },
          phone: {
            model: '',
            number: '',
            focus: false,
            error: '',
            country: undefined
          },
          email: {
            value: '',
            error: ''
          }
        },
        note: {
          show: false,
          body: ''
        },
        callback: true
      },
      contents: {
        show: false
      },
      buttons: {
        checkout: {
          loading: false
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency',
      items: 'tradein/items',
      countries: 'countries'
    }),

    userIsValid () {
      return !this.form.user.name.error &&
        this.form.user.name.value &&
        !this.form.user.phone.error &&
        this.form.user.phone.number &&
        !this.form.user.email.error // &&
        // this.form.user.email.value
    }
  },

  created () {
    this.form.user.phone.country = this.country
  },

  methods: {
    validateNameOnKeydown () {
      if (this.form.user.name.value.length === 0) {
        this.form.user.name.error = ''
      }
    },

    validateNameOnKeyup () {
      if (this.form.user.name.error === '') {
        return
      }

      this.validateName()
    },

    validateName () {
      if (this.form.user.name.value.length >= 3) {
        this.form.user.name.error = ''
      } else if (this.form.user.name.value.length >= 1 && this.form.user.name.value.length < 3) {
        this.form.user.name.error = this.$t('pages.checkout.user.name.errors.length', { length: 2 })
      } else {
        this.form.user.name.error = this.$t('pages.checkout.user.name.errors.required')
      }
    },

    onUserPhoneInput (number, phone) {
      this.form.user.phone.number = phone.number

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.form.user.phone.error = ''
      } else {
        this.form.user.phone.error = this.$t('pages.checkout.user.phone.errors.invalid')
      }
    },

    onRecipientPhoneInput (number, phone) {
      this.form.delivery.recipient.phone.number = phone.number

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.form.delivery.recipient.phone.error = ''
      } else {
        this.form.delivery.recipient.phone.error = this.$t('pages.checkout.user.phone.errors.invalid')
      }
    },

    validateEmailOnKeyup () {
      if (this.form.user.email.value.length === 0) {
        this.form.user.email.error = ''
      }

      if (this.form.user.email.error === '') {
        return
      }

      this.validateEmail()
    },

    validateEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.form.user.email.value)) {
        this.form.user.email.error = ''
      } else {
        this.form.user.email.error = this.$t('pages.checkout.user.email.errors.invalid')
      }
    },

    toggleFormNoteVisibility () {
      this.form.note.show = !this.form.note.show

      if (this.form.note.show) {
        this.$nextTick(() => {
          this.$refs.note.$el.focus()
        })
      }
    },

    submit () {
      this.buttons.checkout.loading = true

      const data = {
        user: {
          name: this.form.user.name.value,
          lastname: this.form.user.lastname.value,
          patronymic: this.form.user.patronymic.value,
          phone: this.form.user.phone.number,
          email: this.form.user.email.value
        },
        note: this.form.note.body,
        items: []
      }

      this.items.forEach((item) => {
        const options = []

        item.options.forEach((option) => {
          options.push({
            id: option.id,
            title: option.title,
            value: {
              id: option.value.id,
              title: option.value.title,
              percent: option.value.percent
            }
          })
        })

        data.items.push({
          product: {
            id: item.product.id,
            price: item.product.price
          },
          options
        })
      })

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/tradeins`, data)
        .then((res) => {
          this.$store.dispatch('tradein/setTradein', res.data)

          this.$router.push(this.localePath({ name: 'tradein-thanks' }))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.buttons.checkout.loading = false))
    }
  }
}
