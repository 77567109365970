
import { mapGetters } from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    Breadcrumbs
  },

  // async asyncData ({ app }) {
  //   const response = await app.$axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/service`)
  //     .catch((error) => {
  //       console.log(error)
  //     })

  //   return {
  //     service: response.data
  //   }
  // },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.service.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      logo: 'logo'
    })
  },

  head () {
    return {
      title: this.$t('pages.service.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.service.description')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.service.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.service.description')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
