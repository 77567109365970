
import { mapGetters } from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    Breadcrumbs
  },

  async asyncData ({ app }) {
    const response = await app.$cacheFetch(
      {
        key: `get:/client/${app.$config.storeId}/${app.i18n.locale}/${app.store.state.country.iso_code}/pages/payment`,
        expire: app.$config.responseCacheLifetime
      },
      async () => {
        return await app.$axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/pages/payment`)
          .catch((error) => {
            console.log(error)
          })
      }
    )

    response.data.payment.forEach((company, index) => {
      response.data.payment[index].methods.map((method, index) => {
        method.isActive = true
      })
    })

    return {
      page: response.data.page,
      payment: response.data.payment
    }
  },

  data () {
    return {

    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    }),
    breadcrumbs () {
      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.page.header,
          to: '#',
          active: true
        }
      ]
    }
  },

  methods: {
    commission (commission) {
      return commission.value === 0
        ? this.$t('pages.payment.commission.free')
        : this.$t('pages.payment.commission.title', {
          value: commission.type === 'percent'
            ? `${commission.value}%`
            : ''
        })
    }
  },

  head () {
    return {
      title: this.page.title,
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: `${this.$config.appUrl}/${this.page.canonical}`
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page.description
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.page.robots
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.page.keywords
        }
      ]
    }
  }
}
