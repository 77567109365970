
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

import Breadcrumbs from '@/components/Breadcrumbs'
import Category from '@/components/Tradein/Category'
import CharacteristicValue from '@/components/Tradein/CharacteristicValue'
import TradeinOption from '@/components/Tradein/TradeinOption'
import Product from '@/components/Tradein/Product'

export default {
  components: {
    Breadcrumbs,
    Category,
    CharacteristicValue,
    TradeinOption,
    Product
  },

  mixins: [
    clickaway
  ],

  async asyncData ({ app, store }) {
    const response = await app.$axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/tradein`)
      .catch((error) => {
        console.log(error)
      })

    store.dispatch('tradein/setCategories', response.data.categories)

    return {
      page: response.data.page
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.tradein.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency',
      categories: 'tradein/categories',
      items: 'tradein/items',
      logo: 'logo'
    }),

    validated () {
      const categories = this.items.map(i => i.category).find(c => c === null)
      const products = this.items.map(i => i.product).find(p => p === null)
      const options = this.items.find(i => i.options.length === 0 || i.options.find(o => o.value === null))

      if (categories !== undefined || products !== undefined || options !== undefined) {
        return false
      } else {
        return true
      }
    }
  },

  created () {
    if (this.items.length === 0) {
      this.addItem()
    }
  },

  methods: {
    addItem () {
      this.$store.dispatch('tradein/addItem', {
        category: null,
        characteristic: {
          id: null,
          title: '',
          value: null,
          values: []
        },
        products: [],
        product: null,
        options: []
      })
    },

    removeItem (index) {
      if (this.items.length <= 1) {
        return
      }

      this.$store.dispatch('tradein/removeItem', index)
    },

    getEstimate (index) {
      let percent = 0

      if (this.items[index].options.length > 0) {
        percent = this.items[index].options
          .filter(o => o.value && o.value.percent)
          .reduce((sum, o) => sum + o.value.percent, 0)
      }

      if (percent === 0) {
        return this.items[index].product.price
      } else if (percent > 0 && percent < 100) {
        return (this.items[index].product.price - (this.items[index].product.price / 100 * percent)).toFixed(this.currency.round)
      } else {
        return 0
      }
    },

    checkout () {
      if (this.validated === false) {
        return
      }

      this.$router.replace(this.localePath({
        name: 'tradein-checkout'
      }))
    }
  },

  head () {
    return {
      title: this.page.title,
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: `${this.$config.appUrl}/${this.page.canonical}`
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page.description
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.page.schema_org_title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.page.schema_org_description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.page.robots
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.page.keywords
        }
      ]
    }
  }
}
