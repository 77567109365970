
import SideMenu from '@/components/user/SideMenu'
import Product from '@/components/product/Product'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    SideMenu,
    Product,
    Breadcrumbs
  },

  middleware: 'auth',

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.index.title'),
          to: this.localePath('user'),
          active: false
        },
        {
          title: this.$t('pages.user.wishlists.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  methods: {

  },

  head () {
    return {
      title: this.$t('pages.user.wishlists.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.wishlists.description')
        }
      ]
    }
  }
}
